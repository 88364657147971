import { createI18n } from 'vue-i18n'
import zhCN from './config/zh-CN'
import enUS from './config/en-US'
import jaJP from './config/ja-JP'

// let messages = {} //声明一个数据模型，对应i18n中的message属性
let messages = {
  'zh-CN': zhCN,
  'en-US': enUS,
  'ja-JP': jaJP,
}
let locale = 'en-US' // localStorage

const i18n = createI18n({
  fallbackLocale: 'ch',
  globalInjection: true,
  legacy: false, // you must specify 'legacy: false' option
  locale,
  messages,
})

export default i18n
