//取随机字符串
export function randomString(e) {
    e = e || 32;
    let t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
    for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
    return n
}

//判断不为空
export function isNotNull(_val) {//  === 用全等判断，区分0和''
  return typeof (_val) != "undefined" && null != _val && _val !== '' && 'null' !== _val;

}
//判断为空
export function isNull(_val) {
  return !isNotNull(_val)
}
