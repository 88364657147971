export default {
  a1YXalQL74P:'KAWA智能云台摄像机',
  a1PWEtshcC8:'CJ2708',
  a1IFRPt2Vfr:'KAWA SMART CAMERA A5',
  a1p82cD5G9P:'KAWA SMART CAMERA A7',
  a1ZKlxKdLYm:'KAWA安防摄像机 T6',
  a1MJM7mNwTG:'KAWA安防摄像机 T6 Pro',
  a1hJB8WWjJZ: 'PA3',
  CloudPackageSet: { // 假如用于翻译表格
    unit_day: '天',
    remain_days: '剩余天数',
    startTip: '开始时间',
    endTip: '到期时间',
    tip1: '未开始',
    using_text: '正在使用中',
    none_commodity_text: '该产品暂无可选套餐',
  },
  MultiDevice:{
    multiDevicePurchases:'多设备购买',
    selectedDev:'选择设备',
    maxSelected:'最多只能选择',
    per:'个',
    tower:'台',
    device:'设备',
    selectedDebMustHaveSame:'选择的设备订单必须具有相同的',
    productKey:'productKey',
    selected:'已选',
    transcribeType:'录制方式',
    continuousRecording:'连续录制',
    eventRecording:'事件录制',
    videoRetentionDays:'视频保存天数',
    year:'年',
    totalAmount:'总金额',
    noDevicePackageExists:'不存在该设备套餐',
    month_valid: '个月有效',
    year_valid: '年有效'
  },
  Coupon:{
    coupon:'优惠卷',
    actualAmountPaid:'实付金额',
    none:'暂无',
    promotionCode:'优惠码',
    pleaseEnterCode:'请输入优惠码',
    // couponCodeError:'优惠码输入错误  请重新输入',
    pleaseEnterAgain:'优惠码输入错误，请重新输入',
    codeUsed:'优惠卷已经使用',
    codeNotStart: '优惠券未达使用时间',
    codeExpired:'优惠卷已经过期',
  },
  ProductDes: {
    month_valid: '一个月有效',
    year_valid: '一年有效'
  },
  FreeCloudDes: {
    not_consumed: '云存储未开通',
    common_description: '云端智能告警回放、不怕坏、不怕丢',
    expired: '云存储试用结束',
    commodity_name:'最近7天视频历史记录',
    commodity_desc:'保持过去7天内因事件触发产生的录像',
    commodity_valid_time:'有效期:30天',
    event_commodity_desc_pre: '记录过去',
    event_commodity_desc_suf: '天，因事件触发的录像，有效期限为30天',
    continuous_commodity_desc_pre: '连续记录过去',
    continuous_commodity_desc_suf: '天的录像，有效期限为30天',
    event_commodity_desc: '事件录像',
    continuity_commodity_desc: '24h连续录像'
  },
  Home: {
    popupCancel: '取消',
    popupPriceDes: '价格',
    popupBuyBtnDes: '立即支付',
    paySetBuyBtnDes1: '购买',
    paySetBuyBtnDes2: '续购',
    paySetBuyBtnDes3: '试用',
    paySetContentTime1: '有效期',
    paySetContentTime2: '日',
    payCancelTip: '支付取消',
    paySuccessTip: '支付成功',
    getFreeSuccess: '领取成功',
    confirm: '确认',
    payFailureTip: '是否完成支付?',
    continuePaying: '继续支付',
    back: '返回',
    queryTip: '正在查询支付结果....',
    payNotComplete: '上一个支付未完成',
 },
  FreeCloudSet: {
    tip1: '体验',
    tip2: '月',
    tip3: '正在使用中',
    tip4: '未开通服务',
    tip5: '到期时间',
  },
  DeviceOrderItem: {
    time_des: '到期时间: ',
    button_des0: '试用30天',
    button_des1: '立即开通',
    button_des2: '立即续订',
    button_des3: '已移除',
  },
  CloudServiceDes: {
    title: '视频云存储特权',
    des: '服务开通后，智能摄像机将上传视频到云端，在服务有效期内您可以随时随地在任何一台手机上使用App查看回放数据',
    item_title1: '安全存储',
    item_title2: '隐私双加密',
    item_title3: '多终端畅享',
    item_des1: '录像数据云端存储，即使设备离线、遗失，也能查看之前的录像',
    item_des2: '采用金融级加密方案，数据从传输到存储100%加密保护，做到数据与通道双加密',
    item_des3: '支持多台终端设备同时查看，走到哪，看到哪，视频流畅不卡顿',
  },
  CloudService: {
    tip1: '无支持的设备',
  },
  Order: {
    no_orders: '您还没有相关的订单',
  },
  OrderItem: {
    duration: '有效期',
    time_unit: '日',
    order_time: '下单时间',
    order_sn: '订单编号',
    service_start_time: '服务开始时间',
    service_end_time: '服务到期时间',
    service_status: '服务状态',
    pay_status: '支付状态',
    pay_continue: '继续支付',
    service_expired: '已到期',
    service_using: '正在使用',
    pay_success: '支付成功',
    pay_refund: '已退款',
    pay_closed: '超过24小时未支付交易关闭',
    pay_failure: '支付失败',
    received_success: '领取成功',
  },
  qa: {
    first: '联系我们',
  },
  product:{
    recommend_tag:'推荐',
    recommend_tag_hot:'热门',
  },
  checkout:{
    checkout_query_payment_result:'正在查询支付结果...',
    checkout_success_text:'购买成功',
    checkout_wait_payment:'待支付',
    checkout_cancel_order_text:'取消订单',
    checkout_continue_payment_text:'继续支付',
    device_name_text: '设备名:',
    order_create_time_text: '订单生成时间:',
    order_number_text: '订单编号:',
    payment_amount_text: '付款金额:',
    checkout_payment_complete_text: '完成',
  },
  transferDes:{
    complete_button: '完成',
    current_use_device: '当前使用设备',
    relate_device: '选择关联的设备(只能转移给同类产品)',
    transfer: '转移',
    my_cloud_storages: '我的云存储服务',
    my_cloud_storages_explain: '说明：只有购买的云存储套餐才能转移，试用的不能转移'
  },
  promptMessageDes: {
    select_device: '请选择设备',
    set_current: '设置中',
    set_success: '设置成功',
    set_fail: '设置失败，请重试',
    create_order: '下单中',
    none_device: '暂无可转移的设备',
    none_data: '暂无数据'
  }
}
