export default {
  a1YXalQL74P:'KAWA SMART PAN-TILT CAMERA',
  a1PWEtshcC8:'CJ2708',
  a1IFRPt2Vfr:'KAWA SMART CAMERA A5',
  a1p82cD5G9P:'KAWA SMART CAMERA A7',
  a1ZKlxKdLYm:'KAWA Security Camera T6',
  a1MJM7mNwTG:'KAWA Security Camera T6 Pro',
  a1hJB8WWjJZ: 'PA3',
  CloudPackageSet: { // 假如用于翻译表格
    unit_day: 'days',
    remain_days: 'Remaining days',
    startTip: 'Start Time',
    endTip: 'VALID THRU',
    tip1: 'Not Started',
    using_text: 'In Using Now',
    none_commodity_text: 'No optional package',
  },
  MultiDevice:{
    multiDevicePurchases:'Multi-device purchases',
    selectedDev:'Select devices',
    maxSelected:'You can only select up to',
    per:'',
    tower:'',
    device:'device',
    selectedDebMustHaveSame:'The selected device order must have the same',
    productKey:'productKey',
    selected:'Selected',
    transcribeType:'Recording Methods',
    continuousRecording:'Continuous recording',
    eventRecording:'Event recording',
    videoRetentionDays:'Video retention days',
    year:'year',
    totalAmount:'Total amount',
    noDevicePackageExists:'The device package does not exist',
    month_valid: 'month validity',
    year_valid: 'year validity'
  },
  Coupon:{
    coupon:'Coupon',
    actualAmountPaid:'Payable amount',
    none:'No available',
    promotionCode:'Coupon code',
    pleaseEnterCode:'Please enter the coupon code',
    pleaseEnterAgain:'Wrong coupon code, please re-enter',
    codeUsed:'Coupon has been used',
    codeNotStart: 'Coupon not valid',
    codeExpired:'Coupon has expired',
  },
  ProductDes: {
    month_valid: 'Valid for one month',
    year_valid: 'Valid for one year'
  },
  FreeCloudDes: {
    not_consumed: 'Cloud Storage Is Not Turned On',
    common_description: 'Cloud Smart Alarm Playback, Not Afraid Of Bad, Not Afraid Of Losing',
    expired: 'The Cloud Storage Trial Is Over',
    commodity_name:'Last 7 days video history',
    commodity_desc:'Maintain Recordings Generated By Event Triggers In The Last 7 Days.',
    commodity_valid_time:'Expiration Date:30 Days',
    event_commodity_desc_pre: 'Event recording for past ',
    event_commodity_desc_suf: ' days.This service is valid for 30 days',
    continuous_commodity_desc_pre: 'Continuously record video footage of the past ',
    continuous_commodity_desc_suf: ' days, valid for 30 days',
    event_commodity_desc: 'Event recording',
    continuity_commodity_desc: '24h continuous recording'
  },
  Home: {
    popupCancel: 'Cancel',
    popupPriceDes: 'Price',
    popupBuyBtnDes: 'Pay Now',
    paySetBuyBtnDes1: 'Purchase',
    paySetBuyBtnDes2: 'repurchase',
    paySetBuyBtnDes3: 'Trial',
    paySetContentTime1: 'Expiration Date',
    paySetContentTime2: ' Days',
    payCancelTip: 'Payment Cancellation',
    paySuccessTip: 'The Payment Was Successful',
    getFreeSuccess: 'Received Successfully',
    confirm: 'Sure',
    payFailureTip: 'Do You Want To Complete The Payment?',
    continuePaying: 'Continue',
    back: 'Back',
    queryTip: 'Querying Payment Results...',
    payNotComplete: 'The last payment was not completed',
  },
  FreeCloudSet: {
    tip1: 'experience',
    tip2: 'mon',
    tip3: 'in use',
    tip4: 'Service not activated',
    tip5: 'Expire date',
  },
  DeviceOrderItem: {
    time_des: 'Expiration: ',
    button_des0: '30d trial',
    button_des1: 'Subscribe',
    button_des2: 'Renew',
    button_des3: 'Removed',
  },
  CloudServiceDes: {
    title: 'Cloud Storage of video',
    des: 'Once the service is enabled, the smart camera will upload video to the cloud, allowing you to playback from anywhere, at any time, on any phone during the service period',
    item_title1: 'Secure storage',
    item_title2: 'Two-layer encryption for privacy',
    item_title3: 'Unlimited access from multiple devices',
    item_des1: 'Cloud storage of video allows you to view previous recordings even if your device is offline or lost',
    item_des2: 'Data is 100% encrypted at rest and in transit with financial-grade encryption',
    item_des3: 'Supports viewing from multiple devices simultaneously, no matter where you are, with smooth streaming',
  },
  CloudService: {
    tip1: 'No Supported Devices',
  },
  Order: {
    no_orders: 'No Orders',
  },
  OrderItem: {
    duration: 'Valid period',
    time_unit: 'Day',
    order_time: 'Order time',
    order_sn: 'Order no',
    service_start_time: 'Service Start Time',
    service_end_time: 'Service Expiration Time',
    service_status: 'Service Status',
    pay_status: 'Payment Status',
    pay_continue: 'Continue',
    service_expired: 'Expired',
    service_using: 'In Use',
    pay_success: 'The Payment Was Successful',
    pay_refund: 'Refunded',
    pay_closed: 'More Than 24 Hours Unpaid Transaction Closed',
    pay_failure: 'Payment Failed',
    received_success: 'Received Successfully',
  },
  qa: {
    first: 'Contact Us',
  },
  product:{
    recommend_tag:'recommend',
    recommend_tag_hot:'Hot Selling',
  },
  checkout:{
    checkout_query_payment_result:'Query Payment Result...',
    checkout_success_text:'Complete Payment',
    checkout_wait_payment:'Waiting Payment',
    checkout_cancel_order_text:'cancel order',
    checkout_continue_payment_text:'Continue Payment',
    device_name_text: 'Device Name:',
    order_create_time_text: 'Create Time:',
    order_number_text: 'Transaction ID:',
    payment_amount_text: 'Payment Total Amount:',
    checkout_payment_complete_text: 'Complete',
  },
  transferDes:{
    complete_button: 'Finish',
    current_use_device: 'device currently in use',
    relate_device: 'Select associated device(Can only be transferred to similar products)',
    transfer: 'transfer',
    my_cloud_storages: 'My package',
    my_cloud_storages_explain: 'Note: Only purchased cloud storage packages can be transferred, and trial ones cannot be transferred'
  },
  promptMessageDes: {
    select_device: 'Please select a device',
    set_current: 'setting',
    set_success: 'set successfully',
    set_fail: 'Setup failed, please try again',
    create_order: 'under order',
    none_device: 'There are currently no devices to transfer',
    none_data: 'No data'
  }
}
