import i18n from '../i18n'
import * as StringUtil from '~/kawa/utils/StringUtil'

const {t, locale} = i18n.global

export function getQuery(param) {
  let query = window.location.href.substring(window.location.href.indexOf('?') + 1)
  query = query.replace('#', '')
  query = query.replace('/', '')
  // 通过&开始分隔
  let vars = query.split('&')
  // 对vars数组中的每个字符串通过&分隔
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=')
    // 分割完之后得到的数组进行判断，拿到需要的参数
    if (pair[0] === param) {
      if (StringUtil.isNull(pair[1])) {
        return '';
      }
      return pair[1]
    }
  }
  return ''
}

export function getI18n(key) {
  return t(key)
}

export function setI18nLang(lang) {
  locale.value = lang
}

export function getImageUrl(url) {
  return 'url(' + new URL(url, import.meta.url).toString() + ')'
}
