export default {
  a1YXalQL74P:'KAWAスマートパン・チルトカメラ',
  a1PWEtshcC8:'CJ2708',
  a1IFRPt2Vfr:'KAWA SMART CAMERA A5',
  a1p82cD5G9P:'KAWA SMART CAMERA A7',
  a1ZKlxKdLYm:'KAWA防犯カメラT6',
  a1MJM7mNwTG:'KAWA防犯カメラT6 Pro',
  a1hJB8WWjJZ: 'PA3',
  CloudPackageSet: { // 假如用于翻译表格
    unit_day: '日',
    remain_days: '残りの日数',
    startTip: '開始時刻',
    endTip: '終了時刻',
    tip1: '開始されていません',
    using_text: '使用中です',
    none_commodity_text: 'オプションコースはございません',
  },
  MultiDevice:{
    multiDevicePurchases:'マルチデバイス購入',
    selectedDev:'デバイスの選択',
    maxSelected:'最大選択数',
    per:'ヶ',
    tower:'台',
    device:'デバイス',
    selectedDebMustHaveSame:'選択された機器オーダーは、同じ',
    productKey:'productKey',
    selected:'Selected',
    transcribeType:'録画方法',
    continuousRecording:'連続録画',
    eventRecording:'イベント録画',
    videoRetentionDays:'ビデオ保持日数',
    year:'年',
    totalAmount:'総額',
    noDevicePackageExists:'そのようなパッケージは存在しない',
    month_valid: 'ヶ月有効',
    year_valid: '年有効'
  },
  Coupon:{
    coupon:'クーポン',
    actualAmountPaid:'支払額',
    none:'なし',
    promotionCode:'クーポンコード',
    pleaseEnterCode:'クーポンコードを入力してください',
    pleaseEnterAgain:'クーポンコードが正しくありません',
    codeUsed:'クーポン使用済み',
    codeNotStart: 'クーポン無効',
    codeExpired:'クーポンの有効期限は切れています',
  },
  ProductDes: {
    month_valid: '1ヶ月有効',
    year_valid: '1年有効'
  },
  FreeCloudDes: {
    not_consumed: 'クラウド ストレージが開かれていない',
    common_description: 'クラウドインテリジェントアラーム再生、悪い恐れ、失うことを恐れていません',
    expired: 'クラウド ストレージの試用が終了しました',
    commodity_name:'過去7日間の録画履歴',
    commodity_desc:'過去 7 日間にイベント トリガーによって生成された録画を保持します。',
    commodity_valid_time:'有効期間:30 日',
    event_commodity_desc_pre: '過去',
    event_commodity_desc_suf: '日間、イベントによってトリガーされたビデオを30日間有効に記録します',
    continuous_commodity_desc_pre: '過去',
    continuous_commodity_desc_suf: '日間の動画を継続的に録画し、30日間有効',
    event_commodity_desc: 'イベント録画',
    continuity_commodity_desc: '24時間連続録画'
  },
  Home: {
    popupCancel: 'キャンセル',
    popupPriceDes: '価格',
    popupBuyBtnDes: '今すぐ支払う',
    paySetBuyBtnDes1: '購入',
    paySetBuyBtnDes2: '续购',
    paySetBuyBtnDes3: '試用する',
    paySetContentTime1: '有効期間',
    paySetContentTime2: '日',
    payCancelTip: 'キャンセルの支払い',
    paySuccessTip: '支払いは成功しました',
    getFreeSuccess: '受領成功',
    confirm: '確認',
    payFailureTip: '支払いは完了しますか?',
    continuePaying: '支払いを続行します',
    back: '戻る',
    queryTip: '支払結果を照会しています...',
    payNotComplete: '最後の支払いが完了していません',
},
  FreeCloudSet: {
    tip1: '経験',
    tip2: '月',
    tip3: '使用中で',
    tip4: 'サービスがアクティブ化されていません',
    tip5: '有効期限',
  },
  DeviceOrderItem: {
    time_des: '有効期限: ',
    button_des0: 'トライアル',
    button_des1: '今すぐオープン',
    button_des2: '更新します',
    button_des3: '削除されました',
  },
  CloudServiceDes: {
    title: 'Video クラウド ストレージの特権',
    des: 'サービスが開始されると、スマートカメラはクラウドにビデオをアップロードし、サービスの有効期間中にいつでも、どこでも、任意の携帯電話でアプリを使用して再生データを表示できます',
    item_title1: '安全なストレージ',
    item_title2: 'プライバシーデュアル暗号化',
    item_title3: 'マルチターミナルで楽しもう',
    item_des1: '録画データクラウドストレージは、デバイスがオフラインで紛失した場合でも、以前の録画を表示します',
    item_des2: '金融レベルの暗号化スキームにより、データは転送からストレージまで100%暗号化され、データとチャネルは二重に暗号化されます',
    item_des3: '複数のエンド デバイスを同時に表示し、どこへ行くか、どのデバイスを見るか、ビデオがスムーズに立ち往生しないようにサポートします',
  },
  CloudService: {
    tip1: 'サポートされていないデバイス',
  },
  Order: {
    no_orders: 'オーダーなし',
  },
  OrderItem: {
    duration: '有効期間',
    time_unit: '日',
    order_time: '注文時間',
    order_sn: '注文番号',
    service_start_time: 'サービス開始時間',
    service_end_time: 'サービスの有効期限',
    service_status: 'サービスの状態',
    pay_status: '支払ステータス',
    pay_continue: '支払いを続行します',
    service_expired: '有効期限が切れています',
    service_using: '使用中です',
    pay_success: '支払いは成功しました',
    pay_refund: '払い戻し済み',
    pay_closed: '24時間以上未払いの取引はクローズされます',
    pay_failure: '支払い失敗',
    received_success: '受領成功',
  },
  qa: {
    first: 'お問い合わせ下さい',
  },
  product:{
    recommend_tag:'お勧め',
    recommend_tag_hot:'人気',
  },
  checkout:{
    checkout_query_payment_result:'支払い結果の確認...',
    checkout_success_text:'購入の成功',
    checkout_wait_payment:'支払われる',
    checkout_cancel_order_text:'注文をキャンセルする',
    checkout_continue_payment_text:'支払いを続ける',
    device_name_text: '機器名:',
    order_create_time_text: '注文生成時間:',
    order_number_text: '注文番号:',
    payment_amount_text: '支払金額:',
    checkout_payment_complete_text: '終了',
  },
  transferDes:{
    complete_button: '終了',
    current_use_device: '現在使用中のデバイス',
    relate_device: '関連するデバイスを選択(類似商品への乗り換えのみ可能)',
    transfer: '移行',
    my_cloud_storages: '私のクラウドストレージサービス',
    my_cloud_storages_explain: '注: 購入したクラウド ストレージ パッケージのみ転送でき、試用版は転送できません'
  },
  promptMessageDes: {
    select_device: 'デバイスを選択してください',
    set_current: '設定',
    set_success: '正常に設定',
    set_fail: 'セットアップに失敗しました。もう一度やり直してください',
    create_order: '注文中',
    none_device: '現在、転送するデバイスはありません',
    none_data: 'データなし'
  }
}
