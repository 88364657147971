import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import './global.css'
import { Button, Card, List, Popup, Cell, CellGroup, Radio, RadioGroup,Checkbox, CheckboxGroup, Toast, Loading, Overlay,Form, Field } from 'vant'
import { Col, Row } from 'vant';
import './less/RestToast.less'

// Vue.config.productionTip = false

const app = createApp(App)
app.use(router)
app.use(i18n)
app.use(Button)
app.use(Card)
app.use(List)
app.use(Popup)
app.use(Cell)
app.use(CellGroup)
app.use(Radio)
app.use(RadioGroup)
app.use(Checkbox)
app.use(CheckboxGroup)
app.use(Form)
app.use(Field)
app.use(Toast)
app.use(Loading)
app.use(Overlay)
app.use(Col);
app.use(Row);
app.mount('#app')
