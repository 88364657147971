import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('~/kawa/views/Home/Home.vue')
    ,
  },
  {
    path: '/qa/dev',
    name: 'QA',
    component: () => import('~/kawa/views/QA/QA.vue'),
  },
  {
    path: '/qa/self',
    name: 'SelfQA',
    component: () => import('~/kawa/views/QA/SelfQA.vue'),
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('~/kawa/views/Order/Order.vue'),
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('~/kawa/views/Products/Products.vue'),//进去之后才真正根据app=KAWA选择对应的vue文件
  },
  {
    path: '/myCloudStorages',
    name: 'MyCloudStorages',
    component: () => import('~/kawa/views/MyCloudStorages/MyCloudStorages.vue'),//进去之后才真正根据app=KAWA选择对应的vue文件
  },
  {
    path: '/multiDevice',
    name: 'MultiDevice',
    component: () => import('~/kawa/views/Home/Components/MultiDevice.vue'),//进去之后才真正根据app=KAWA选择对应的vue文件
  },

  {
    path: '/transfer',
    name: 'Transfer',
    component: () => import('~/kawa/views/Transfer/Transfer.vue'),//进去之后才真正根据app=KAWA选择对应的vue文件
    props: route => ({
      initProductKey: route.query.productKey,
      initIotId: route.query.iotId,
      initNickName:route.query.nickName,
      initIdentityId: route.query.identityId,
      initAppType: route.query.app,
      initSrcOrderId: route.query.aliOrderSn,
      initRegionId: route.query.regionId,
      initLang:route.query.lang,
      initRecordType: route.query.recordType,
      initCommodityName: route.query.commodityName,
      initRemainDays: route.query.remainDays,
    })
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('~/kawa/views/Checkout/index.vue'),
    props: route => ({ initIdentityId: route.query.identityId,
      initIotId: route.query.iotId,
      initAppType: route.query.app,
      initmallOrderUuid: route.query.mallOrderUuid,
      initPayType: route.query.payType,
      initRegionId: route.query.regionId,
      initLang:route.query.lang,
      initToken: route.query.token,
      initPayerID: route.query.PayerID,
      type:route.query.type,
      from:route.query.from,
    })
  },
  {
    path: '/user/agreement',
    name: 'UserAgreement',
    component: () => import('~/kawa/views/UserAgreement/UserAgreement.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory  (),
  routes,
})

export default router
